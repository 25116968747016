<template>
  <div class="season-schedule">
    <div class="main">
      <div class="top">
        <div class="left">
          <div class="title">第二階段例行賽賽程</div>
          <div class="sub-title">SECOND ROUND</div>
        </div>
        <div class="right">
          <span>SAT. 13:30</span>
          <span>WED. 20:30</span>
        </div>
      </div>

      <div class="tables desktop">
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>場次</th>
              <th>日期</th>
              <th colspan="4">出賽隊伍</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>1</td>
              <td>
                <span>08/04</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">A</div>
                  <div class="team-label d">D</div>
                  <div class="team-label b">B</div>
                  <div class="team-label g">G</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <span>08/07</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label h">h</div>
                  <div class="team-label b">B</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <span>08/11</span>
                <span>WED.</span>
              </td>
              <td class="teams">
                <div class="teams">
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                <span>08/14</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label g">g</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                <span>08/18</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                <span>08/21</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                <span>08/25</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                <span>08/28</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label h">h</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>場次</th>
              <th>日期</th>
              <th colspan="4">出賽隊伍</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>9</td>
              <td>
                <span>09/01</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label b">b</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>
                <span>09/04</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label d">d</div>
                  <div class="team-label b">b</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                <span>09/08</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>
                <span>09/15</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                  <div class="team-label g">g</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>
                <span>09/25</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label b">b</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>14</td>
              <td>
                <span>10/02</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                  <div class="team-label g">g</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>15</td>
              <td>
                <span>10/09</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="intro">
            <span>隊伍可以以『 雀 』為單位更換選手出戰 </span>
            <span>每位選手最低出場 8 雀</span>
          </div>
        </div>
      </div>
      <div class="tables mobile">
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>場次</th>
              <th>日期</th>
              <th colspan="4">出賽隊伍</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>1</td>
              <td>
                <span>08/04</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">A</div>
                  <div class="team-label d">D</div>
                  <div class="team-label b">B</div>
                  <div class="team-label g">G</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <span>08/07</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label h">h</div>
                  <div class="team-label b">B</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <span>08/11</span>
                <span>WED.</span>
              </td>
              <td class="teams">
                <div class="teams">
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                <span>08/14</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label g">g</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                <span>08/18</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                <span>08/21</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                <span>08/25</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                <span>08/28</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label h">h</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>
                <span>09/01</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label b">b</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>
                <span>09/04</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label d">d</div>
                  <div class="team-label b">b</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                <span>09/08</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>
                <span>09/15</span>
                <span>WED.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                  <div class="team-label g">g</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>
                <span>09/25</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label b">b</div>
                  <div class="team-label g">g</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>14</td>
              <td>
                <span>10/02</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label d">d</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                  <div class="team-label g">g</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>15</td>
              <td>
                <span>10/09</span>
                <span>SAT.</span>
              </td>
              <td>
                <div class="teams">
                  <div class="team-label a">a</div>
                  <div class="team-label h">h</div>
                  <div class="team-label b">b</div>
                  <div class="team-label c">c</div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="intro">
          <span>隊伍可以以『 雀 』為單位更換選手出戰 </span>
          <span>每位選手最低出場 8 雀</span>
        </div>
      </div>
      <div class="teamScore">
        <teamScore></teamScore>
      </div>
    </div>
  </div>
</template>

<script>
import teamScore from '@/components/content/teamScore'
export default {
  name: "schedule",
  components: {teamScore},
}
</script>

<style scoped lang="scss">
$fontAddition: 1;
.season-schedule {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  .main {
    flex: 1;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;

    .top{
      display:flex;
      .left{
        flex:1;
        .title {
          width: 100%;
          text-align: left;
          font-size: 1.4rem *$fontAddition;
          line-height: 1.6rem *$fontAddition;
          font-family: 'Hiragino Kaku Gothic Std';
          color: #DDDDDD;
        }

        .sub-title {
          width: 100%;
          text-align: left;
          font-size: 1.2rem *$fontAddition;
          line-height: 1.2rem *$fontAddition;
          font-family: 'ReservoirGrunge';
          color: #DDDDDD;
          margin-top: 12px;
        }
      }
      .right{
        flex:1;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        span{
          width: 100%;
          text-align: right;
          font-size: 1.4rem *$fontAddition;
          line-height: 1.6rem *$fontAddition;
          font-family: 'Hiragino Kaku Gothic Std';
          color: #DDDDDD;
        }
      }
    }



    .tables {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      @include responsive('min', 768px) {
        &.mobile {
          display: none;
        }
      }
      @include responsive('max', 767.5px) {
        &.desktop {
          display: none;
        }
        &.mobile {
          flex-direction: column;
        }
      }

      .intro {
        font-family: Noto Sans TC;
        color: #D8F203;
        display: flex;
        flex-direction: column;
        padding-top: .5rem;
        font-size: 1rem;
        font-weight: 900;
      }

      .table {
        flex: 1;
        @include responsive('min', 992px) {
          &:first-child {
            margin-right: .5rem;
          }
        }
        @include responsive('max', 991.5px) {
          &:first-child {
            margin-right: .3rem;
          }
        }
        @include responsive('max', 414px) {
          &.desktop {
            display: none;
          }
        }


        table {
          width: 100%;

          thead {
            background-color: #D8F203;
            border: 1px solid #D8F203;

            th {
              color: #000;
              white-space: nowrap;
              text-align: center;
              font-size: 1.2rem *$fontAddition;
              line-height: 1.2rem *$fontAddition;
              font-weight: 900;
              font-family: 'Hiragino Kaku Gothic Std';
            }

          }

          tbody {


            td {

              .team-label {
                text-transform: uppercase;
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                &.a {
                  background-color: #EF7F22;
                }

                &.b {
                  background-color: #1C8DC0;
                }

                &.c {
                  background-color: #F422E0;
                }

                &.d {
                  background-color: #722DE0;
                }

                &.e {
                  background-color: #F20C23;
                }

                &.f {
                  background-color: #009E42;
                }

                &.g {
                  background-color: #BC7500;
                }

                &.h {
                  background-color: #B3B5B6;
                }

                .team {
                  color: #000;
                  font-size: 1rem;
                  font-family: 'ReservoirGrunge';
                }
              }

              padding: 0;
              border: 2px solid #D8F203;
              text-align: center;
              font-size: 1rem *$fontAddition;
              font-family: 'ReservoirGrunge';
              color: #D8F203;


              &:first-child {
                padding-top: 1rem;
              }

              &:nth-child(2) {
                display: flex;
                flex-direction: column;
              }

              .teams {
                display: flex;
                justify-content: space-around;
              }

              .team-label {
                width: 2rem *$fontAddition;
                height: 2rem *$fontAddition;
                font-size: 1rem *$fontAddition;
                padding-top: 4px;
                border-radius: 50%;
                margin: 6px;
                font-family: 'ReservoirGrunge';
                color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .center {
                display: flex;
                justify-content: center;
                margin-top: 6px;

                .la {
                  position: relative;
                  margin: 0 auto;

                  .slabel {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    background-color: #722DE0;
                    content: 'S';
                    color: #DDDDDD;
                    font-size: 26.5px*$fontAddition;
                    position: absolute;
                    top: -5px;
                    left: -15px;
                    padding-top: 4px;
                  }

                  ._team {
                    width: 107.78px*$fontAddition;
                    height: 50px*$fontAddition;
                    border-radius: 50%;
                    font-family: 'ReservoirGrunge';
                    background-color: #B9DD0A;
                    color: #000;
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    align-items: flex-end;
                    position: relative;
                    z-index: 2;


                    span {
                      &:nth-child(1) {
                        font-size: 35.72px*$fontAddition;
                        line-height: 30.72px*$fontAddition;
                        padding-right: 4px;
                      }

                      &:nth-child(2) {
                        font-size: 21.43px*$fontAddition;
                        line-height: 20.43px*$fontAddition;
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }


            }
          }
        }
      }
    }
  }

}

</style>
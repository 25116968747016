<template>
  <div class="season-registration">
    <div class="ititle">SML第三季賽事報名</div>
    <p class="small-notice">請詳細填寫報名資料，送出後不可修改且不可重複報名</p>
    <div class="formGroup" style="margin-top: 1rem;">
      <div class="title">
        真實姓名:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.realName" type="text"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        性別:
      </div>
      <div class="formContent">
        <div class="radio-group">
          <input class="register-radio" id="male" v-model="registration.gender" value="1" type="radio"/>
          <label class="radioLabel" for="male">男</label>
          <div class="check"></div>
        </div>
        <div class="radio-group">
          <input class="register-radio" id="female" v-model="registration.gender" value="0" type="radio"/>
          <label class="radioLabel" for="female">女</label>
          <div class="check"></div>
        </div>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        生日:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.birthday" type="date"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        電話號碼:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.phone" type="text"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        Email:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.mail" type="text"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        職業:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.profession" type="text"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        牌齡:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.mahjongAge" type="text"/>
      </div>
    </div>
    <div class="formGroup break">
      <div class="title">
        曾參加過什麼麻將節目/比賽:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.experience" type="text"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        Instagram:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.ig" type="text"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        Facebook:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.fb" type="text"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        Youtube:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.yt" type="text"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        Line:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.line" type="text"/>
      </div>
    </div>
    <div class="formGroup break">
      <div class="title">
        半年內是否有出境紀錄:
      </div>
      <div class="formContent">
        <div class="radio-group">
          <input class="register-radio" id="isOutbound1" v-model="registration.isOutbound" value="1" type="radio"/>
          <label class="radioLabel" for="isOutbound1">有</label>
          <div class="check"></div>
        </div>
        <div class="radio-group">
          <input class="register-radio" id="isOutbound0" v-model="registration.isOutbound" value="0" type="radio"/>
          <label class="radioLabel" for="isOutbound0">無</label>
          <div class="check"></div>
        </div>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        配合上鏡:
      </div>
      <div class="formContent">
        <div class="radio-group">
          <input class="register-radio" id="withPhotogenic1" v-model="registration.withPhotogenic" value="1" type="radio"/>
          <label class="radioLabel" for="withPhotogenic1">是</label>
          <div class="check"></div>
        </div>
        <div class="radio-group">
          <input class="register-radio" id="withPhotogenic0" v-model="registration.withPhotogenic" value="0" type="radio"/>
          <label class="radioLabel" for="withPhotogenic0">否</label>
          <div class="check"></div>
        </div>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        有無交通工具:
      </div>
      <div class="formContent">
        <div class="radio-group">
          <input class="register-radio" id="haveTransportation1" v-model="registration.haveTransportation" value="1" type="radio"/>
          <label class="radioLabel" for="haveTransportation1">有</label>
          <div class="check"></div>
        </div>
        <div class="radio-group">
          <input class="register-radio" id="haveTransportation0" v-model="registration.haveTransportation" value="0" type="radio"/>
          <label class="radioLabel" for="haveTransportation0">無</label>
          <div class="check"></div>
        </div>
      </div>
    </div>
    <p class="small-notice">比賽地點近中和交流道</p>
    <div class="formGroup">
      <div class="title">
        時間是否配合:
      </div>
      <div class="formContent">
        <div class="radio-group">
          <input class="register-radio" id="haveTime1" v-model="registration.haveTime" value="1" type="radio"/>
          <label class="radioLabel" for="haveTime1">是</label>
          <div class="check"></div>
        </div>
        <div class="radio-group">
          <input class="register-radio" id="haveTime0" v-model="registration.haveTime" value="0" type="radio"/>
          <label class="radioLabel" for="haveTime0">否</label>
          <div class="check"></div>
        </div>
      </div>

    </div>
    <p class="small-notice">2022年1月-2022年6月 週三20:30及週六13:30</p>
    <div class="formGroup">
      <div class="title">
        異性隊友真實名字:
      </div>
      <div class="formContent">
        <input class="register-input" v-model="registration.partnerName" type="text"/>
      </div>
    </div>
    <div class="formGroup">
      <div class="title">
        個人照:
      </div>
      <div class="formContent">
        <input class="register-input" ref="uploadImg" type="file" @change="onFileChange"/>
      </div>
    </div>
    <div class="formGroup" v-if="image !== ''">
      <div class="preview">
        <div class="removeImg" @click="removeImage">
          <svgIcon :type="'close'"></svgIcon>
        </div>
        <img :src="image"/>
      </div>
    </div>
    <div class="">
      <div class="submit-btn" @click="checkForm()">送出</div>
    </div>
  </div>
</template>

<script>
import svgIcon from '@/components/_layout/SvgIcon'

export default {
  name: "registration",
  components: {svgIcon},
  data() {
    return {
      registration: {
        seasonId: 3,
        realName: '',
        partnerName: '', //	異性隊友真實名字
        gender: 1,
        birthday: '',
        profession: '', //	職業
        mahjongAge: '', //牌齡
        experience: '', //曾參加過什麼麻將節目或麻將比賽
        ig: '',
        fb: '',
        yt: '',
        phone: '',
        line: '',
        mail: '',
        image: '',
        isOutbound: 0, //是否有出入境紀錄
        withPhotogenic: 1, //配合上鏡
        haveTransportation: 1, //有無交通工具
        haveTime: 1, //時間是否配合
      },
      // registration: {
      //   seasonId: 3,
      //   realName: '13',
      //   partnerName: '2342', //	異性隊友真實名字
      //   gender: 1,
      //   birthday: '2023-8-10',
      //   profession: '10def', //	職業
      //   mahjongAge: '343', //牌齡
      //   experience: '', //曾參加過什麼麻將節目或麻將比賽
      //   ig: '23423',
      //   fb: '',
      //   yt: '',
      //   phone: '12345',
      //   line: '23423',
      //   mail: '432423@gmail.com',
      //   image: '',
      //   isOutbound: 0, //是否有出入境紀錄
      //   withPhotogenic: 1, //配合上鏡
      //   haveTransportation: 1, //有無交通工具
      //   haveTime: 1, //時間是否配合
      // },
      image: '',
      requireData: [
        'realName', 'birthday', 'profession', 'mahjongAge', 'ig',
        'phone', 'line', 'mail', 'partnerName', 'image',
      ]
    }
  },
  methods: {
    checkForm: function () {
      var check = true
      Object.keys(this.registration).forEach((i) => {
        if (check) {
          if (i === 'birthday') {
            var data = this.registration[i].split('-')
            if (parseInt(data[0]) >= 2003 ) {
              if(parseInt(data[1]) >= 10 ){
                if(parseInt(data[2]) > 9){
                  this.$bus.$emit('showPopup')
                  this.$bus.$emit('popupMessage', '參加本次賽事需滿18歲，請確定出生年月日是否有誤')
                  check = false
                }
                this.$bus.$emit('showPopup')
                this.$bus.$emit('popupMessage', '參加本次賽事需滿18歲，請確定出生年月日是否有誤')
                check = false
              }
              this.$bus.$emit('showPopup')
              this.$bus.$emit('popupMessage', '參加本次賽事需滿18歲，請確定出生年月日是否有誤')
              check = false
            }
          }
          if (this.requireData.includes(i) && this.registration[i] === '') {
            this.callMessage(i)
            check = false
          }
        }
      })
      if (check) {
        this.submitData()
      }
    },
    callMessage: function (text) {
      this.$bus.$emit('showPopup')
      this.$bus.$emit('popupMessage', '送出失敗，' + this.translate(text) + '為必填欄位')
    },
    translate: function (i) {
      var data = {
        realName: '真實姓名',
        partnerName: '異性隊友真實姓名',
        birthday: '生日',
        profession: '職業',
        mahjongAge: '牌齡',
        ig: 'Instagram',
        phone: '電話號碼',
        line: 'Line ID',
        mail: '電子郵件',
        image: '個人照',
      }
      return data[i]
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.registration.image = file;
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.image = '';
      this.registration.image = '';
      this.$refs.uploadImg.value = '';
    },
    submitData: function () {
      this.$bus.$emit('showPopup')
      this.$bus.$emit('popupMessage', '送出報名資料中，請勿關閉')
      this.$store.dispatch('registration', {credentials: this.registration}).then((res) => {
        if (!res.errorCode) {
          this.$router.push({name: 'Home'}).catch(() => {

          });
          this.$bus.$emit('goBack')
          this.$bus.$emit('popupMessage', '已收到報名資料，請靜候聯繫')
        } else {
          if (res.errorCode == 2003) {
            this.$bus.$emit('popupMessage', '請勿重複報名')
          }
        }
      })
    },
  },
  mounted() {
    this.$bus.$emit('registration')
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/registration';

</style>
<template>
  <div class="tabs">
    <ul>
      <li :class="active == 'tab1'?'active':''" @click="chooseTabs('tab1')"><span>簡介</span></li>
      <li :class="active == 'tab2'?'active':''" @click="chooseTabs('tab2')"><span>賽事</span></li>
<!--      <li :class="active == 'tab3'?'active':''" @click="chooseTabs('tab3')"><span>報名</span></li>-->
    </ul>
  </div>
</template>

<script>
  export default {
    name: "tabs",
    props: ['active'],
    data() {
      return {}
    },
    methods: {
      chooseTabs: function (el) {
        var tab = (this.active == el) ? '' : el;
        this.$bus.$emit('chooseTabs', tab)
      },
    },
    mounted: function () {
      // var $this =this

    }
  }
</script>

<style scoped lang="scss">
  .tabs {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 2.5rem;
        text-align: center;
        color: #D8F203;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 900;
        cursor: pointer;
        font-family: Hiragino Kaku Gothic Std;
        border-right: 1px solid #D8F203;
        transition-delay: .5s;
        transition: all 1s linear;

        &:last-child {
          border-right: 0;
        }

        &.active {
          flex: 3;
          background-color: #D8F203;
          color: #000;
        }
      }
    }
  }
</style>

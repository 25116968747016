<template>
  <div class="teams">
    <div class="team" v-for="(item,idx) in teams" v-show="showDetail">
      <div class="data">
        <div class="sort" v-html="getRankStr(idx+1)"></div>
        <div class="team-label" :style="'background-color:'+item.color" v-text="item.displayName"></div>
        <div class="team-point" :style="'color:'+item.color">
          <span>Team <br>Points</span>
        </div>
        <div class="team-score" :style="'color:'+item.color">
          <span v-text="item.teamScore"></span>
        </div>
      </div>
      <!--      <div class="detail " :class="item.id == showDetail?'show':''" :style="'color:'+item.color" v-if="item.id == showDetail">-->
      <div class="detail show" :style="'color:'+item.color">
        <!--          <div class="detail"  :style="'color:'+item.color" >-->
        <!--        <div>-->
        <!--          <div>出場場數 <span v-text="parseInt(item.detail.allGames/2)+'/10'"></span></div>-->
        <!--        </div>-->
        <div>
          <div>自摸數 <span v-text="item.detail.tsumoCount"></span></div>
          <div>胡牌數 <span v-text="item.detail.winCount"></span></div>
          <div>放槍數 <span v-text="item.detail.loseCount"></span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "teamScroe",
  data() {
    return {
      active: '',
      season: {},
      teams: {},
      showDetail: ''
    }
  },
  methods: {
    getRankStr: function (idx) {
      var data = ''
      switch (idx) {
        case 1:
          data = '<span>1</span><span class="label">st</span>'
          break;
        case 2:
          data = '<span>2</span><span class="label">nd</span>'
          break;
        case 3:
          data = '<span>3</span><span class="label">rd</span>'
          break;
        case 4:
          data = '<span>4</span><span class="label">th</span>'
          break;
        case 5:
          data = '<span>5</span><span class="label">th</span>'
          break;
        case 6:
          data = '<span>6</span><span class="label">th</span>'
          break;
        case 7:
          data = '<span>7</span><span class="label">th</span>'
          break;
        case 8:
          data = '<span>8</span><span class="label">th</span>'
          break;
      }
      return data
    },
    getData: function () {
      this.$store.dispatch('getData', {type: 'Season', credentials: 16}).then((res) => {
        if (!res.errorCode) {
          this.season = res.data
          this.teams = res.data.teams
          this.teams.sort(function (a, b) {
            return a.teamRank - b.teamRank
          })
          this.$store.dispatch('teamDetailBySeason', {credentials: 16}).then((res) => {
            if (!res.errorCode) {
              this.teams.forEach((team) => {
                res.data.forEach((item) => {
                  if (team.id == item.teamId) {
                    team.detail = item.data
                  }
                })
              })
              this.showDetail = true;
            }
          })
        }
      })
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style lang="scss">
@import '../../assets/style/teamScore';
</style>
<template>
  <div class="top-banner">
    <img class="origin-banner" :class="showTab.length == 0? 'active':''" :src="banner" alt="">
    <img class="lite-banner" :class="showTab.length !== 0? 'active':''" :src="litebanner" alt="" @click="goBack()">
  </div>
</template>

<script>
  const banner = require('@/assets/images/phoenix_banner.png');
  const litebanner = require('@/assets/images/banner.png');
  export default {
    name: "topHeader",
    props: ['scrolled', 'scrollNum', 'showTab'],
    data() {
      return {
        banner: banner,
        litebanner: litebanner,
        mobNavActive: false,
      }
    },
    methods: {
      goBack: function () {
        this.$bus.$emit('goBack')
      }
    },
    watch: {
      // '$route': {
      //   handler: function () {
      //     this.mobNavActive = false
      //   },
      //   deep: true,
      //   immediate: true
      // },

    }
  }
</script>

<style lang="scss">
  @import '../../assets/style/header';
</style>

<template>
  <div class="intro">
    <div class="ititle">鳳凰盃</div>
    <div class="icontent">
      SML第二季賽季是鳳凰盃，基本賽制維持一男一女兩人一組的隊伍積分競賽，這次我們徵選了8支參賽隊伍參賽，並將賽季時間拉長；增加了一階段的分組淘汰賽。這季能戰到最後季後賽的隊伍，將會是一路過關斬將、具實力的強力組合。
    </div>
    <div class="ititle">選手</div>
    <div class="icontent">
      <div class="players">
        <div class="player">
          <div class="team-label a"><span class="team">A</span></div>
          <div class="name a">韋智</div>
        </div>
        <div class="player">
          <div class="team-label a"><span class="team">A</span></div>
          <div class="name a">韋孟</div>
        </div>
        <div class="player">
          <div class="team-label b"><span class="team">B</span></div>
          <div class="name b">杯尼</div>
        </div>
        <div class="player">
          <div class="team-label b"><span class="team">B</span></div>
          <div class="name b">宜臻</div>
        </div>
        <div class="player">
          <div class="team-label c"><span class="team">C</span></div>
          <div class="name c">小鄭</div>
        </div>
        <div class="player">
          <div class="team-label c"><span class="team">C</span></div>
          <div class="name c">高高</div>
        </div>
        <div class="player">
          <div class="team-label d"><span class="team">D</span></div>
          <div class="name d">阿億</div>
        </div>
        <div class="player">
          <div class="team-label d"><span class="team">D</span></div>
          <div class="name d">先先</div>
        </div>
        <div class="player">
          <div class="team-label e"><span class="team">E</span></div>
          <div class="name e">肯尼</div>
        </div>
        <div class="player">
          <div class="team-label e"><span class="team">E</span></div>
          <div class="name e">鮭魚</div>
        </div>
        <div class="player">
          <div class="team-label f"><span class="team">F</span></div>
          <div class="name f">泰迪</div>
        </div>
        <div class="player">
          <div class="team-label f"><span class="team">F</span></div>
          <div class="name f">羅伊</div>
        </div>
        <div class="player">
          <div class="team-label g"><span class="team">G</span></div>
          <div class="name g">小花</div>
        </div>
        <div class="player">
          <div class="team-label g"><span class="team">G</span></div>
          <div class="name g">肉肉</div>
        </div>
        <div class="player">
          <div class="team-label h"><span class="team">H</span></div>
          <div class="name h">西米</div>
        </div>
        <div class="player">
          <div class="team-label h"><span class="team">H</span></div>
          <div class="name h">涵涵</div>
        </div>
      </div>
    </div>
    <div class="ititle">賽事規則</div>
    <div class="icontent">
      <ul>
        <li>捨出一張非胡的牌即完成過水，完成過水後方可吃、碰、胡；補槓牌與暗槓牌視同捨牌。過水未完成而胡牌者， 即為詐胡。</li>
        <li>明槓槓上自摸、明槓上家捨出的牌，判定為相公。若有爭議或選手提出異議由裁判定奪。</li>
        <li>花胡後補花完成牌局結束，莊家台另計。可視情況放棄花胡不須過水。若牌敦數不夠無法補花、則無法花胡。</li>
        <li>藏花者經裁判判定後，該選手視為詐胡。</li>
        <li>無雙響、有三響。攔胡發生時，若過水，手接觸到牌墩則表示放棄胡牌，不得胡牌否則以詐胡論。</li>
        <li>輪到選手摸牌時，若手接觸到牌墩表示放棄吃牌、碰牌、胡牌。上牌墩則不在此限制內，若有爭議或選手提出異議由裁判定奪。</li>
        <li>允許喊碰不碰、允許喊胡不胡，胡牌、自摸以倒牌為最後判定，允許叫牌反悔、不罰點數。若多次刻意無意義叫牌反悔，經裁判判定後，該選手則以詐胡論。</li>
        <li>現搭不一定要吃、碰、槓，但當局無法再吃、再碰或胡該搭；可以自摸。 現搭後捨牌即表示完成吃、碰、槓牌，無法再更換搭子，若發生拿錯搭或無法吃、碰、槓情形則判定為相公，並由裁判暫時中止牌局做有限度的回朔，以不影響原先摸牌順序為原則判定。 若有爭議或選手提出異議由裁判定奪。</li>
        <li>禁止使用言語影響牌局，如明確喊出牌章、宣告聽牌或下車等，若有爭議或選手提出異議由裁判定奪。</li>
        <li>碰最大無假溜規則，胡牌或碰牌必須要叫牌明確中止摸牌動作。</li>
        <li>不允許眼牌、不允許蓋牌、不允許打骰前後過牌。</li>
        <li>若沒有牌能摸時則不允許吃、碰、明槓，允許暗槓但需要先打開再暗槓。</li>
        <li>配牌時抓錯若經提醒可復原則不罰，若已打開手牌或不可復原情況下，經裁判判定後由第一位抓錯的人相公。</li>
        <li>補花需明確喊出過補下一家才能補牌，若無明確喊出過補下一家已經補牌則下一家判定相公； 若無明確喊出過補但已捨第一張牌則判定為相公、其他家可依照順序補牌，若有爭議或選手提出異議由裁判定奪。</li>
        <li>同一人同一牌局內第二次相公則視為詐胡。</li>
        <li>流局時須翻開暗槓手牌供其他家、裁判檢查。若為天聽、地聽牌局結束時亦須攤牌供裁判檢查。</li>
        <li>最後一張牌必須要摸、要捨牌。若摸到花、補槓、暗槓則無法再補牌。</li>
        <li>尾端剩16張牌，無牌可摸時則流局連莊。</li>
        <li>海底自摸定義為最後一張摸牌自摸，與槓上自摸可以並存。</li>
        <li>自摸禁止靠進或插入手牌中，天胡允許直接倒牌，不另計獨聽、槓上自摸。</li>
        <li>無骰規規則。</li>
        <li>每局牌局結束後由裁判確認胡牌完成後，不再回追前一把失誤或追判詐胡。</li>
        <li>每隊每一風可有一次可以詢問各家點數的權利，在牌局結束後可以立即查詢，禁止預約時間點申請查詢。</li>
        <li>非緊急情況下，選手禁止於賽事進行中離開牌桌，若有爭議或選手提出異議由裁判定奪。</li>
        <li>禁止攜帶電子通訊設備上場比賽，如智慧型手錶、智慧型手機等，若有爭議或選手提出異議由裁判定奪。</li>
        <li>以現場填寫小卡方式決定當日先發選手、第二雀上場選手。</li>
        <li>若胡牌斷橋牌章至海底牌章相混或無法復原時，由裁判判定詐胡。</li>
        <li>若出現規章未提之爭議，裁判有權暫停比賽，進行檢視回放與判定。</li>
      </ul>
    </div>
    <div class="ititle">所有牌型台數一覽</div>
    <div class="icontent">
      <ul>
        <li>天胡24台</li>
        <li>天聽16台</li>
        <li>地胡16台</li>
        <li>人胡16台</li>
        <li>地聽8台</li>
        <li>自摸1台</li>
        <li>莊家1台 (莊家連N = 2N+1台)</li>
        <li>門前清自摸3台 ( 門清 + 自摸 + 不求人 )</li>
        <li>海底撈月1台</li>
        <li>槓上自摸1台</li>
        <li>場風1台</li>
        <li>正花1台</li>
        <li>正字1台 (有三張才有1台)</li>
        <li>三元牌(中/發/白) 1台 (有三張才有1台)</li>
        <li>花槓2台</li>
        <li>獨聽1台（單吊、邊章、中洞，只聽1張的情況）</li>
        <li>門清1台</li>
        <li>搶槓1台</li>
        <li>全求人2台（不另計獨聽、無半求人）</li>
        <li>平胡2台（門清無平胡，聽兩頭以上皆可，吊將不算，抓得出眼睛就有）</li>
        <li>碰碰胡4台</li>
        <li>混一色4台</li>
        <li>清一色8台</li>
        <li>字一色16台</li>
        <li>三暗刻2台</li>
        <li>四暗刻5台</li>
        <li>五暗刻8台</li>
        <li>小三元4台</li>
        <li>大三元8台</li>
        <li>小四喜8台</li>
        <li>大四喜16台</li>
        <li>七搶一8台</li>
        <li>八仙過海8台</li>
        <li>詐胡1底8台+莊家台</li>
      </ul>
    </div>
    <div class="notice">
      <router-link :to="{ name: 'Registration'}">
        SML第三季賽事報名
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "intro",

  data() {
    return {
      active: '',
      season: {},
      teams: {},
      showDetail: ''
    }
  },
  methods: {
    chooseTabs: function (el) {
      var tab = (this.active == el) ? '' : el;
      this.active = tab;
      this.$bus.$emit('chooseTabs', tab)
    },
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/style/playerInfo';
.notice {
  width: 100%;
  text-align: center;
  font-family: Noto Sans TC;
  color: #D8F203;
  display: flex;
  flex-direction: column;
  padding-top: .5rem;
  a{
    color: #D8F203;
  }

  font-weight: 900;
  @include responsive('min', 768px) {
    font-size: 1.5rem;
  }
  @include responsive('max', 767.5px) {
    font-size: 1rem;
  }
}
</style>

<template>
  <div class="season-schedule">
    <div class="main">
      <div class="top">
        <div class="left">
          <div class="title">季後賽</div>
          <div class="sub-title">PLAY OFFS</div>
        </div>
      </div>
      <div class="tables desktop">
        <div class="table">
          <table>
            <thead>
            <tr>
              <th colspan="9">場次/日期</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <span class="title">1</span>
                <div class="dates">
                  <span>10/13</span>
                  <span>WED.</span>
                </div>
              </td>
              <td>
                <span class="title">2</span>
                <div class="dates">
                  <span>10/16</span>
                  <span>SAT.</span>
                </div>
              </td>
              <td>
                <span class="title">3</span>
                <div class="dates">
                  <span>10/20</span>
                  <span>WED.</span>
                </div>
              </td>
              <td>
                <span class="title">4</span>
                <div class="dates">
                  <span>10/27</span>
                  <span>WED.</span>
                </div>
              </td>
              <td>
                <span class="title">5</span>
                <div class="dates">
                  <span>11/03</span>
                  <span>WED.</span>
                </div>
              </td>
              <td>
                <span class="title">6</span>
                <div class="dates">
                  <span>11/10</span>
                  <span>WED.</span>
                </div>
              </td>
              <td>
                <span class="title">7</span>
                <div class="dates">
                  <span>11/17</span>
                  <span>WED.</span>
                </div>
              </td>
              <td>
                <span class="title">8</span>
                <div class="dates">
                  <span>11/24</span>
                  <span>WED.</span>
                </div>
              </td>
              <td>
                <span class="title">9</span>
                <div class="dates">
                  <span>11/27</span>
                  <span>SAT.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="teams">
                  <div class="team-label h">H</div>
                  <div class="team-label a">A</div>
                  <div class="team-label d">D</div>
                  <div class="team-label b">B</div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tables mobile">
        <div class="table">
          <table>
            <thead>
            <tr>
              <th colspan="3">場次/日期</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <span class="title">1</span>
              </td>
              <td>
                <div class="dates">
                  <span>10/13</span>
                  <span>WED.</span>
                </div>
              </td>
              <td rowspan="9" class="fill">
                <div class="teams">
                  <div class="team-label h">H</div>
                  <div class="team-label a">A</div>
                  <div class="team-label d">D</div>
                  <div class="team-label b">B</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span class="title">2</span>
              </td>
              <td>
                <div class="dates">
                  <span>10/16</span>
                  <span>SAT.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span class="title">3</span>
              </td>
              <td>
                <div class="dates">
                  <span>10/20</span>
                  <span>WED.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span class="title">4</span>
              </td>
              <td>
                <div class="dates">
                  <span>10/27</span>
                  <span>WED.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span class="title">5</span>
              </td>
              <td>
                <div class="dates">
                  <span>11/03</span>
                  <span>WED.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span class="title">6</span>
              </td>
              <td>
                <div class="dates">
                  <span>11/10</span>
                  <span>WED.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span class="title">7</span>
              </td>
              <td>
                <div class="dates">
                  <span>11/17</span>
                  <span>WED.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span class="title">8</span>
              </td>
              <td>
                <div class="dates">
                  <span>11/24</span>
                  <span>WED.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span class="title">9</span>
              </td>
              <td>
                <div class="dates">
                  <span>11/27</span>
                  <span>SAT.</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="notice">
        週三比賽時間 20:30 / 週六比賽時間 13:30
      </div>
            <div class="teamScore">
              <teamScore></teamScore>
            </div>
      <div class="notice">
        <router-link :to="{ name: 'Registration'}">
          SML第三季賽事報名
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import teamScore from '@/components/content/teamScore'

export default {
  name: "schedule",
  components: {teamScore},
}
</script>

<style scoped lang="scss">
$fontAddition: 1;
.season-schedule {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  .main {
    flex: 1;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;

    .top {
      display: flex;

      .left {
        flex: 1;

        .title {
          width: 100%;
          text-align: left;
          font-size: 3rem *$fontAddition;
          line-height: 3rem *$fontAddition;
          font-family: 'Hiragino Kaku Gothic Std';
          color: #DDDDDD;
        }

        .sub-title {
          width: 100%;
          text-align: left;
          font-size: 2rem *$fontAddition;
          line-height: 2rem *$fontAddition;
          font-family: 'ReservoirGrunge';
          color: #DDDDDD;
          margin-top: 12px;
        }
      }

      .right {
        flex: 1;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;

        span {
          width: 100%;
          text-align: right;
          font-size: 1.4rem *$fontAddition;
          line-height: 1.6rem *$fontAddition;
          font-family: 'Hiragino Kaku Gothic Std';
          color: #DDDDDD;
        }
      }
    }

    .tables {
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;

      &.desktop {
        .teams {
          display: flex;
          justify-content: center;
          padding: 2rem;

          .team-label {
            margin: 0 1rem;
          }
        }
      }

      &.mobile {
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 3rem;

        }

        .teams {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 8rem 0;

          .team-label {
            margin: 1rem 0;
          }
        }
      }

      @include responsive('min', 768px) {

        &.mobile {
          display: none;
        }
      }
      @include responsive('max', 767.5px) {
        &.desktop {
          display: none;
        }
        &.mobile {
          flex-direction: column;
        }
      }

      .intro {
        font-family: Noto Sans TC;
        color: #D8F203;
        display: flex;
        flex-direction: column;
        padding-top: .5rem;
        font-size: 1rem;
        font-weight: 900;
      }

      .table {
        flex: 1;
        @include responsive('min', 992px) {
          &:first-child {
            margin-right: .5rem;
          }
        }
        @include responsive('max', 991.5px) {
          &:first-child {
            margin-right: .3rem;
          }
        }
        @include responsive('max', 414px) {
          &.desktop {
            display: none;
          }
        }


        table {
          width: 100%;
          max-width: 100%;

          thead {
            background-color: #D8F203;
            border: 1px solid #D8F203;

            th {
              color: #000;
              white-space: nowrap;
              border: 0;
              text-align: center;
              font-size: 1.2rem *$fontAddition;
              line-height: 1.2rem *$fontAddition;
              font-weight: 900;
              font-family: 'Hiragino Kaku Gothic Std';
            }

          }

          tbody {

            td {
              padding: 1.5rem;


              .dates {
                display: flex;
                flex-direction: column;
              }

              .team-label {
                text-transform: uppercase;
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                &.a {
                  background-color: #EF7F22;
                }

                &.b {
                  background-color: #1C8DC0;
                }

                &.c {
                  background-color: #F422E0;
                }

                &.d {
                  background-color: #722DE0;
                }

                &.e {
                  background-color: #F20C23;
                }

                &.f {
                  background-color: #009E42;
                }

                &.g {
                  background-color: #BC7500;
                }

                &.h {
                  background-color: #B3B5B6;
                }

                .team {
                  color: #000;
                  font-size: 1rem;
                  font-family: 'ReservoirGrunge';
                }
              }

              border: 2px solid #D8F203;
              text-align: center;
              font-size: 1rem *$fontAddition;
              font-family: 'ReservoirGrunge';
              color: #D8F203;


              .team-label {
                width: 6rem *$fontAddition;
                height: 6rem *$fontAddition;
                font-size: 3rem *$fontAddition;
                padding-top: 4px;
                border-radius: 50%;
                font-family: 'ReservoirGrunge';
                color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .center {
                display: flex;
                justify-content: center;
                margin-top: 6px;

                .la {
                  position: relative;
                  margin: 0 auto;

                  .slabel {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    background-color: #722DE0;
                    content: 'S';
                    color: #DDDDDD;
                    font-size: 26.5px*$fontAddition;
                    position: absolute;
                    top: -5px;
                    left: -15px;
                    padding-top: 4px;
                  }

                  ._team {
                    width: 107.78px*$fontAddition;
                    height: 50px*$fontAddition;
                    border-radius: 50%;
                    font-family: 'ReservoirGrunge';
                    background-color: #B9DD0A;
                    color: #000;
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    align-items: flex-end;
                    position: relative;
                    z-index: 2;


                    span {
                      &:nth-child(1) {
                        font-size: 35.72px*$fontAddition;
                        line-height: 30.72px*$fontAddition;
                        padding-right: 4px;
                      }

                      &:nth-child(2) {
                        font-size: 21.43px*$fontAddition;
                        line-height: 20.43px*$fontAddition;
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }


            }
          }
        }
      }
    }

    .notice {
      width: 100%;
      text-align: center;
      font-family: Noto Sans TC;
      color: #D8F203;
      display: flex;
      flex-direction: column;
      padding-top: .5rem;
      a{
        color: #D8F203;
      }

      font-weight: 900;
      @include responsive('min', 768px) {
        font-size: 1.5rem;
      }
      @include responsive('max', 767.5px) {
        font-size: 1rem;
      }
    }
  }

}

</style>
<template>
  <div class="footer">
    <sponsor></sponsor>
    © 2021 boyplaymj. All Rights Reserved.
  </div>
</template>

<script>
import sponsor from '@/components/layout/sponsor'
export default {
  name: "rootFooter",
  components: {sponsor},
}
</script>

<style scoped lang="scss">
//@import '../../assets/style/footer';
.footer {
  width: 100%;
  text-align: center;
  padding: .5rem 0;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import wrapper from '@/components/wrapper'
import sml from '@/components/sml'
import registration from '@/components/content/registration'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		component: wrapper,
		children: [
			{
				path: 'sml',
				name: 'Home',
				component: sml
			},
			{
				path: 'registration',
				name: 'Registration',
				component: registration
			},
		]
	},
]

const router = new VueRouter({
	routes,

})
export default router

<template>
  <div class="icon">
    <svg v-if="type == 'close'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329 328.99">
      <path
          d="M738.64,709.09,866.75,581a21.31,21.31,0,0,0-30.14-30.14L708.5,679,580.39,550.84A21.31,21.31,0,0,0,550.25,581L678.36,709.09,550.25,837.2a21.31,21.31,0,0,0,30.14,30.14L708.5,739.23,836.61,867.34a21.31,21.31,0,0,0,30.14-30.14Zm0,0"
          transform="translate(-544 -544.59)"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "SvgIcon",
  props: ['type', 'text']
}
</script>

<style scoped>

</style>